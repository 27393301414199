revenueChart = function(id, data){
  var revenueChartCanvas = $(id).get(0).getContext('2d');

  var revenueChartData = {
    labels  : data.map(x=>x[0]),
    datasets: [
      {
        label               : '推定合計収益',
        backgroundColor     : 'rgba(40, 167, 69, 0.8)',
        borderColor         : 'rgba(40, 167, 69, 0.8)',
        pointColor          : 'rgba(40, 167, 69, 0.8)',
        data                : data.map(x=>x[1])
      },
      {
        label               : '推定広告収益',
        backgroundColor     : 'rgba(255, 193, 7, 1)',
        borderColor         : 'rgba(255, 193, 7, 1)',
        pointColor          : 'rgba(255, 193, 7, 1)',
        data                : data.map(x=>x[2])
      },
      {
        label               : 'YoutubePremium収益',
        backgroundColor     : 'rgba(23, 162, 184, 1)',
        borderColor         : 'rgba(23, 162, 184, 1)',
        pointColor          : 'rgba(23, 162, 184, 1)',
        data                : data.map(x=>x[3])
      },
      {
        label               : 'トランザクション収益',
        backgroundColor     : 'rgba(220, 53, 69, 1)',
        borderColor         : 'rgba(220, 53, 69, 1)',
        pointColor          : 'rgba(220, 53, 69, 1)',
        data                : data.map(x=>x[1] - x[2] - x[3])
      }
    ]
  };

  var revenueChartOptions = {
    maintainAspectRatio : false,
    responsive : true,
    legend: {
      display: true
    },
    scales: {
      xAxes: [{
        gridLines : {
          display : false,
        }
      }],
      yAxes: [{
        gridLines : {
          display : false,
        }
      }]
    }
  };

  // This will get the first returned node in the jQuery collection.
  new Chart(revenueChartCanvas, {
    type: 'line',
    data: revenueChartData,
    options: revenueChartOptions
  });
};
