dataTable = function(id){
  $(id).DataTable(
    {
      "order": [[ 0, 'asc' ]],
      "destroy": true,
      "language": {
        "url": "//cdn.datatables.net/plug-ins/1.10.25/i18n/Chinese.json"
      }
    }
  );
}
