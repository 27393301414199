const images = require.context('../images', true)
import '../stylesheets/application';

import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'
Rails.start()
ActiveStorage.start()

import 'bootstrap'
import '@fortawesome/fontawesome-free/js/all'
import 'moment'
import 'admin-lte'
import 'chart.js/dist/Chart.js'
import 'daterangepicker'
import 'datatables.net-bs4'
import '../stylesheets/revenue';

import 'packs/libs/bootstrap-select.min.js';
import 'packs/selectpicker.js'
import 'packs/libs/bootstrap-datepicker.min.js';
import 'packs/datepicker.js'

import 'packs/youtube_query_form.js'
import 'packs/youtube_revenue.js'
import 'packs/youtube_subscriber.js'
import 'packs/youtube_views.js'
import 'packs/youtube_datatable.js'
import 'packs/daily_base_chart.js'

$('[data-toggle="tooltip"]').tooltip();
