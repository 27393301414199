
$.fn.selectpicker.Constructor.BootstrapVersion = '4';
$(() => {
  $('.js-datepicker_yyyymm_with_submit').datepicker({
    format: 'yyyy/mm',
    autoclose: true,
    minViewMode: 'months'
  }).on('changeMonth', (e) => {
    setTimeout(() => {
      $(e.currentTarget).closest('form').submit();
    }, 100);
  });
});
