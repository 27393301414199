dailyBaseChart = function(id, label, data, index){
  var chartCanvas = $(id).get(0).getContext('2d');

  var chartData = {
    labels  : data.map(x=>x[0]),
    datasets: [
      {
        label               : label,
        backgroundColor     : 'rgba(60,141,188,0.9)',
        borderColor         : 'rgba(60,141,188,0.8)',
        pointColor          : 'rgba(60,141,188,0.8)',
        data                : data.map(x=>x[index])
      }
    ]
  };

  var chartOptions = {
    maintainAspectRatio : false,
    responsive : true,
    legend: {
      display: true
    },
    scales: {
      xAxes: [{
        gridLines : {
          display : false,
        }
      }],
      yAxes: [{
        gridLines : {
          display : false,
        }
      }]
    }
  };

  // This will get the first returned node in the jQuery collection.
  new Chart(chartCanvas, {
    type: 'line',
    data: chartData,
    options: chartOptions
  });
};
