subscriberChange = function(id){  
// Get context with jQuery - using jQuery's .get() method.
  var subscriberChartCanvas = $(id).get(0).getContext('2d');

  var subscriberChartData = {
    labels  : daily_report.map(x=>x[0]),
    datasets: [
      {
        label               : '登録者数増',
        backgroundColor     : 'rgba(60,141,188,0.9)',
        borderColor         : 'rgba(60,141,188,0.8)',
        pointColor          : 'rgba(60,141,188,0.8)',
        data                : daily_report.map(x=>x[5])
      },
      {
        label               : '登録者数減',
        backgroundColor     : 'rgba(210, 214, 222, 1)',
        borderColor         : 'rgba(210, 214, 222, 1)',
        pointColor          : 'rgba(210, 214, 222, 1)',
        data                : daily_report.map(x=>x[6])
      },
    ]
  };

  var subscriberChartOptions = {
    maintainAspectRatio : false,
    responsive : true,
    legend: {
      display: true
    },
    scales: {
      xAxes: [{
        gridLines : {
          display : false,
        }
      }],
      yAxes: [{
        gridLines : {
          display : false,
        }
      }]
    }
  };

  // This will get the first returned node in the jQuery collection.
  new Chart(subscriberChartCanvas, {
    type: 'line',
    data: subscriberChartData,
    options: subscriberChartOptions
  });
};

subscriber = function(id){
  // Get context with jQuery - using jQuery's .get() method.
  var subscriberChartCanvas = $(id).get(0).getContext('2d');

  var subscriberChartData = {
    labels  : daily_report.map(x=>x[0]),
    datasets: [
      {
        label               : '登録者数',
        backgroundColor     : 'rgba(60,141,188,0.9)',
        borderColor         : 'rgba(60,141,188,0.8)',
        pointColor          : 'rgba(60,141,188,0.8)',
        data                : daily_report.map(x=>x[7])
      }
    ]
  };

  var subscriberChartOptions = {
    maintainAspectRatio : false,
    responsive : true,
    legend: {
      display: true
    },
    scales: {
      xAxes: [{
        gridLines : {
          display : false,
        }
      }],
      yAxes: [{
        gridLines : {
          display : false,
        }
      }]
    }
  };

  // This will get the first returned node in the jQuery collection.
  new Chart(subscriberChartCanvas, {
    type: 'line',
    data: subscriberChartData,
    options: subscriberChartOptions
  });
}
